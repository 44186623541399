import React from 'react';
import './App.css';

import {ThemeProvider} from '@material-ui/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Articles from "./Components/Articles";
import InfoTab from "./Components/InfoTab";
import {useAuth0} from "./react-auth0-spa";
// New - import the React Router components, and the Profile page component
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Profile from "./Components/Profile";
import Typography from "@material-ui/core/Typography";
import LiveHelpIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Button from "@material-ui/core/Button";

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#585551'
        },
        primary: {
            main: '#3b5998'
        },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Lato"',
            'sans-serif'
        ].join(',')
    }
});

function App() {
    const {loading} = useAuth0();

    if (loading) {
        return (
            <div>
                <Typography variant="h5" gutterBottom>
                    Sayfa Yükleniyor...
                </Typography>
                <Typography gutterBottom>
                    Yüklenme tamamlanmazsa
                    <Button variant="outlined" color="secondary"
                            onClick={() => window.open('https://blog.sinaviyerim.com/')}
                            startIcon={<LiveHelpIcon/>}
                            style={{marginLeft: 20}}>
                        sayfayı yenile.
                    </Button>
                </Typography>
            </div>
        );
    }
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact/>
                    <Route path="/profile" component={Profile}/>
                </Switch>
                <ThemeProvider theme={theme}>
                    <div>
                        <div>
                            <InfoTab/>
                        </div>
                        <div>
                            <Articles/>
                        </div>
                    </div>
                </ThemeProvider>
            </BrowserRouter>

        </div>
    );
}

export default App;
