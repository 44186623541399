import React from 'react';
import {useAuth0} from "../react-auth0-spa";
import BlackButtonBar from "./button/BlackButtonBar";

const NavBar = () => {
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();

    return (
        <div>
            {!isAuthenticated && (
                <BlackButtonBar
                    text="Kaydol"
                    redirect={loginWithRedirect}
                />
            )}
            {isAuthenticated &&
            <BlackButtonBar
                text="Çıkış"
                redirect={logout}
            />}
        </div>
    );
};

export default NavBar;
