import React, {Component} from 'react';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Article from './articles/Article';

import {getAllArticles} from "../api";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";

const backgroundShape = require('../images/shape.svg');

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['A500'],
        overflow: 'hidden',
        background: `url(${backgroundShape}) no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        marginTop: 20,
        padding: 20,
        paddingBottom: 150
    },
    gridList: {
        flexWrap: 'nowrap',
        marginBottom: 40
    },
    tileBar: {
        height: 'auto'
    }
});

function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: null,
        };
    }

    componentDidMount() {
        getAllArticles((allArticles) => {
            // sort articles by publish dates
            let sortedArticles = allArticles.sort((a, b) => b.publishDate > a.publishDate);
            this.setState({articles: sortedArticles})
        });
    }

    createArticleComponents = () => {
        let articleComponents = [];
        let articles = this.state.articles;
        if (articles != null) {
            for (let i = 0; i < articles.length; i++) {
                let article = [];
                article.push(
                    <Grid
                        item
                        xs={11}
                        key={i}
                    >
                        <Article
                            name={articles[i]['name']}
                            logo={articles[i]['logo']}
                            link={articles[i]['link']}
                            summary={articles[i]['summary']}
                            publishDate={articles[i]['publishDate']}
                            accessTypeVal={articles[i]['accessTypeVal']}
                            categoryVal={articles[i]['categoryVal']}
                        />
                    </Grid>);
                articleComponents.push(article);
            }
        }
        return articleComponents;
    };

    createFavoriteArticleComponents = () => {
        const {classes} = this.props;
        let favoriteArticleComponents = [];
        if (this.state.articles == null) {
            return;
        }
        let favoriteArticles = this.state.articles.slice();
        favoriteArticles = shuffleArray(favoriteArticles);
        for (let i = 0; i < favoriteArticles.length; i++) {
            if (favoriteArticles[i] == null) {
                continue;
            }
            let isFavoriteArticle = favoriteArticles[i]['favorite'];
            if (isFavoriteArticle === false || isFavoriteArticle == null) {
                continue;
            }
            let article = [];
            article.push(
                <GridListTile
                    onClick={() => window.open(favoriteArticles[i]['link'])}
                    src={favoriteArticles[i]['link']}>
                    <img src={favoriteArticles[i]['logo']} alt={favoriteArticles[i]['name']}/>
                    <GridListTileBar
                        title={
                            <p style={{whiteSpace: 'normal'}}>
                                {favoriteArticles[i]['name']}
                            </p>
                        }
                        className={classes.tileBar}
                    />
                </GridListTile>);
            favoriteArticleComponents.push(article);
        }
        return favoriteArticleComponents;
    };

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <CssBaseline/>
                <div className={classes.root}>
                    <GridList className={classes.gridList} cols={2.5}>
                        {this.createFavoriteArticleComponents()}))
                    </GridList>
                    <br/>
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                        spacing={1}
                    >
                        {this.createArticleComponents()}
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Cards);
