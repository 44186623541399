import React, {Component} from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import ButtonBar from "../button/ButtonBar";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        borderRadius: 12
    },
    avatar: {
        margin: 1,
        width: '200px',
        height: '100px',
        borderRadius: 10,
        backgroundColor: theme.palette.grey['200'],
        color: theme.palette.text.primary,
    },
    avatarImg: {
        maxWidth: '100%',
        height: 'auto'
    },
    avatarContainer: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginBottom: theme.spacing(4)
        }
    },
    itemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    baseline: {
        width: '70%',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: 0
        }
    },
    inline: {
        width: '90%',
        display: 'inline-block',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    inlineRight: {
        width: '10%',
        textAlign: 'right',
        marginLeft: 50,
        alignContent: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
            textAlign: 'center'
        }
    },
    backButton: {
        marginRight: theme.spacing(2)
    },
    secondary: {
        background: theme.palette.secondary['100']
    },
    tag: {
        margin: theme.spacing(0.25)
    }
});

const moment = require('moment');

class CardItem extends Component {

    simplifyDate() {
        let options = {year: 'numeric', month: 'long', day: 'numeric'};
        let momentDate = moment.utc(this.props.publishDate);
        return momentDate.toDate().toLocaleString('tr-TR', options);
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}
                >
                    <div className={classes.itemContainer}
                         onClick={() => window.open(this.props.link)}
                    >
                        <div className={classes.avatarContainer}>
                            <Avatar className={classes.avatar}>
                                <img
                                    className={classes.avatarImg}
                                    alt="Logo"
                                    src={this.props.logo}
                                />
                            </Avatar>
                        </div>
                        <div className={classes.baseline}>
                            <div className={classes.inline}>
                                <Typography variant="h5" gutterBottom>
                                    {this.props.name}
                                </Typography>
                                <Typography color='secondary' gutterBottom>
                                    {this.props.summary}
                                </Typography>

                                {this.simplifyDate()}

                                <br/>
                                <Chip
                                    label={this.props.accessTypeVal}
                                    color="primary"
                                    size="small"
                                    className={classes.tag}
                                />
                                <Chip
                                    label={this.props.categoryVal}
                                    color="primary"
                                    size="small"
                                    className={classes.tag}
                                />
                            </div>
                        </div>
                        <div className={classes.inlineRight}>
                            <ButtonBar
                                text="OKU"
                                colorClass="primary"
                                link={this.props.link}/>
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles)(CardItem);