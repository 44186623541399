import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StarIcon from '@material-ui/icons/Star';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CodeIcon from '@material-ui/icons/Code';

import TouchAppIcon from '@material-ui/icons/TouchApp';
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

// const DialogActions = withStyles(theme => ({
//     root: {
//         margin: 0,
//         padding: theme.spacing(1),
//     },
// }))(MuiDialogActions);

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{marginLeft: 10}}>
            <Button variant="outlined" color="secondary" onClick={handleClickOpen}
                    startIcon={<TouchAppIcon/>}>
                Yenilikler
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="alert-dialog-title">{"Sınavı-Yerim'de Yeni"}</DialogTitle>
                <Grid container spacing={2}>
                    <div>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AnnouncementIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Son eklenen özellik: Görsel düzenlemeler"
                                    secondary="Versiyon 1.2.2 | 24 Ocak 2020"
                                />
                            </ListItem>
                            <Divider variant="middle"/>
                            <ListItem>
                                <ListItemIcon>
                                    <CodeIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Keyifli ve hızlı bir blog sayfası için güçlü teknolojileri biraraya getirdim"
                                    secondary="Meraklısı için Sınavı-Yerim'deki teknolojiler: AWS (RDS, EBS, Cloudfront), JavaScript & React, Java & Spring"
                                />
                            </ListItem>
                        </List>
                    </div>
                </Grid>
            </Dialog>
        </div>
    );
}