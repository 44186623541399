import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import CssBaseline from "@material-ui/core/CssBaseline";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import {getAllArticles, getFilteredArticlesByCategory} from "../api";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const createArticlesComponent = (category, callback) => {
    // Get all articles
    if (category.length < 2) {
        getAllArticles((articles) => {
            createGridListTileWithArticles(articles, articles.length, insideCallback => {
                if (callback) callback(insideCallback);
            });
        });
    }

    // Get filtered articles
    getFilteredArticlesByCategory(category, (articles) => {
        createGridListTileWithArticles(articles, articles.length, insideCallback => {
            if (callback) callback(insideCallback);
        });
    });
};

const getCategoryValue = async (category, callback) => {
    switch (category) {
        case 'MOTIVATION_AND_GOAL':
            callback("Motivasyon ve Hedef Belirleme");
            break;
        case 'LIVING_PROPER':
            callback("Doğru Yaşam");
            break;
        case 'STUDY_TECHNIQUES':
            callback("Çalışma Teknikleri");
            break;
        case 'STUDY_TACTICS':
            callback("Çalışma Taktikleri");
            break;
        case 'PURE_BELIEF':
            callback("Kesin İnanç");
            break;
        case 'PROTECTIVE_SHIELD':
            callback("Koruma Kalkanı");
            break;
        case 'CROWN_OF_SUCCESS':
            callback("Başarının Taçları");
            break;
        default:
            callback("");
            break;
    }
};

const getCategoryExplanation = async (category, callback) => {
    switch (category) {
        case 'MOTIVATION_AND_GOAL':
            callback("Motivasyon ve hedef belirleme bugünkü durumundan, istediğin hedefe ulaşacağın yoldaki tutumunu ifade eder. ");
            break;
        case 'LIVING_PROPER':
            callback("Bu kata gereken değeri vermeden de başarıya ulaşabilirsin ama başarın geçici olur. ");
            break;
        case 'STUDY_TECHNIQUES':
            callback("Hangi şekilde en iyi ve en hızlı öğrendiğini keşfedersin. Bu keşfi hayatın boyunca kullanacaksın.");
            break;
        case 'STUDY_TACTICS':
            callback("Çalışmalarından daha verim alarak zirveye yükselişini hızlandıran kattır.");
            break;
        case 'PURE_BELIEF':
            callback("Bu katta başarılı olmak büyük çaba gerektirir. Ancak etkisi bomba gibidir. İmkansızı gerçeğe dönüştüren evredir.");
            break;
        case 'PROTECTIVE_SHIELD':
            callback("Ailenin, çevrenin ve kendinin olumsuz yorumlarına karşı güçlü durma çalışmalarını içerir.");
            break;
        case 'CROWN_OF_SUCCESS':
            callback("Büyük başarı elde etmiş tüm insanların yaptığı ortak aktiviteleri içerir. ");
            break;
        default:
            callback("");
            break;
    }
};

const createGridListTileWithArticles = (articles, articleCount, callback) => {
    let filteredArticles = [];
    if (articles === undefined || articles === null) {
        return;
    }
    for (let i = 0; i < articleCount; i++) {
        let article = [];
        article.push(
            <GridListTile
                onClick={() => window.open(articles[i]['link'])}
                src={articles[i]['link']}>
                <img src={articles[i]['logo']} alt={articles[i]['name']}/>
                <GridListTileBar
                    title={
                        <p style={{whiteSpace: 'normal'}}>
                            {articles[i]['name']}
                        </p>
                    }
                    style={{height: 'auto'}}
                />
            </GridListTile>);
        console.log(article);
        filteredArticles.push(article);
    }
    callback(filteredArticles);
};

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

// const DialogActions = withStyles(theme => ({
//     root: {
//         margin: 0,
//         padding: theme.spacing(1),
//     },
// }))(MuiDialogActions);

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState('');
    const [categoryVal, setCategoryVal] = React.useState('');
    const [explanation, setExplanation] = React.useState('');
    const [filteredArticlesComponent, setFilteredArticlesComponent] = React.useState([]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateArticles = async event => {
        console.log("Updating articles");
        setCategory(event.target.value);
        await getCategoryVal(event.target.value);
        await getExplanation(event.target.value);
        await createArticlesComponent(event.target.value, callback => {
            setFilteredArticlesComponent(callback);
            console.log("filteredArticlesComponent");
            console.log(filteredArticlesComponent);
        });
    };

    const getCategoryVal = async category => {
        await getCategoryValue(category, (categoryVal) => {
            setCategoryVal(categoryVal);
        });
    };

    const getExplanation = async category => {
        await getCategoryExplanation(category, (explanation) => {
            setExplanation(explanation);
        });
    };

    return (
        <div style={{marginLeft: 10}}>
            <Button variant="outlined" color="secondary" onClick={handleClickOpen}
                    startIcon={<TouchAppIcon/>}>
                Konu sihirbazı
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth = {true}
                maxWidth="md"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <img width="auto" height={25}
                         src="https://sinaviyerim.com/wp-content/uploads/2017/02/Piramit-PSD-Small-Cute2.png"/>
                    Başarı Yolunu Çiz
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Kendini geliştirmek istediğin alanı seç, rehberleri gör.
                    </Typography>

                    <FormControl variant="outlined"
                                 fullWidth={true}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Sorununu seç, rehberleri bul
                        </InputLabel>
                        <Select
                            id="demo-simple-select-outlined"
                            value={category}
                            onChange={updateArticles}
                        >
                            <MenuItem value='MOTIVATION_AND_GOAL'>Motivasyonum düşük</MenuItem>
                            <MenuItem value='LIVING_PROPER'>Çabuk yoruluyorum</MenuItem>
                            <MenuItem value='STUDY_TECHNIQUES'>Konuları yetiştirebilecek miyim?</MenuItem>
                            <MenuItem value='STUDY_TACTICS'>Nasıl daha hızlı öğrenebilirim?</MenuItem>
                            <MenuItem value='PURE_BELIEF'>Başaracağıma tam inanmıyorum</MenuItem>
                            <MenuItem value='PROTECTIVE_SHIELD'>Çevrem beni olumsuz etkiliyor</MenuItem>
                            <MenuItem value='CROWN_OF_SUCCESS'>Nasıl daha büyük başarılar elde ederim?</MenuItem>
                        </Select>
                    </FormControl>

                    <React.Fragment>
                        <CssBaseline/>
                        <div>
                            <b>{categoryVal}</b>
                            <p>
                                {explanation}
                                {explanation.length > 0 ?
                                    <a onClick={() => window.open('https://sinaviyerim.com/basari-piramidi')}>(Detay)</a> : ''}
                            </p>

                            <GridList cols={1.5} style={{flexWrap: 'nowrap', marginBottom: 20}}>
                                {filteredArticlesComponent}
                            </GridList>
                        </div>
                    </React.Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
}